import React, {useState} from "react";
import {
    Button,
    Typography,
    FormControl,
    Box,
    TextField,
    Stack,
    IconButton,
    Divider,
    Grid,
    Link,
} from "@mui/material";
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "../../Firebase";
import PersonIcon from "@mui/icons-material/Person";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BrushIcon from '@mui/icons-material/Brush';
import {useNavigate} from "react-router";

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorLogin, setErrorLogin] = useState(false);

    const navigate = useNavigate();

    const loginSubmit = (e) => {
        e.preventDefault();// prevent reloaded when submitted, so that we don't lose the state of email and password
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                setErrorLogin(false);
                console.log('userCredential', userCredential)
            }).catch((error) => {
            setErrorLogin(true);
            console.log('error in log in:', error)
        })
        navigate('/JiraCloudPlugins')
    }

    return (
        <Box
            height="70vh" // Set the height of the container to 100% of the viewport height
            display="flex" // Use flexbox display
            justifyContent="center" // Horizontally center the content
            alignItems="center" // Vertically center the content
        >
            <Grid>
                <Typography variant={'h6'} color={'primary'}>Login </Typography>
                <Stack direction={'row'}>
                    <IconButton>
                        <PersonIcon fontSize={'large'} edge={'start'} color={'primary'} aria-label={'client-avatar'}/>
                        <VpnKeyIcon fontSize={'small'} edge={'start'} color={'primary'} aria-label={'client-key'}/>
                    </IconButton>
                    <Grid margin={3} width={{xs: 150, sm: 330}}>
                        <Stack component={'form'} onSubmit={loginSubmit}>
                            <FormControl>
                                <TextField
                                    label={'Enter your email'}
                                    name={'email'}
                                    variant="standard"
                                    type={'email'}
                                    value={email}
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <TextField
                                    label={'Enter your password'}
                                    name={'password'}
                                    type={'password'}
                                    variant={'standard'}
                                    value={password}
                                    required
                                    onChange={(e) => setPassword(e.target.value)}/>
                            </FormControl>
                            <Stack height={50}>
                                {errorLogin ? <p>wrong password </p> : null}
                            </Stack>
                            <Button type={'submit'} variant="contained">Login</Button>
                        </Stack>
                    </Grid>
                </Stack>

                <Divider >OR</Divider>

                <Stack direction={'row'}>
                    <IconButton href={'/SignUp'}>
                        <AssignmentIndIcon fontSize={'large'} edge={'start'} color={'secondary'}
                                           aria-label={'client-avatar'}/>
                        <BrushIcon fontSize={'small'} edge={'start'} color={'secondary'}
                                   aria-label={'client-key'}/>
                    </IconButton>
                    <Grid margin={3} width={{xs: 150, sm: 330}}>
                        <Typography fontSize={15} color={'primary'}>Don't have an account? </Typography>
                        <Typography fontSize={12}>It only takes a few seconds to &nbsp;
                            <Link underline={'always'} href={'SignUp'}>
                                create a new account!
                            </Link>
                        </Typography>
                    </Grid>
                </Stack>
            </Grid>
        </Box>
    )
}
export default SignIn