/**
 * it's for frontend functions
 * steps: 1. to see functions/index.js
 *          2. create a variable similar to the function needed to import the function in index.js page, for example:
 *          const addMessageFunction = httpsCallable(functions, 'addmessage');
 *          3. create a function to call and export it,for example:
 *          export const addMessageFunction(messageText)
 *          .then((result) => {
 *              return result.data
 *          }
 *          4. go to the UI page to import and call it,for example:
 *          import {addMessage} from "../query/Query";
 */

import {connectFunctionsEmulator, getFunctions, httpsCallable} from "firebase/functions";
import firebaseApp from "../Firebase";

const functions = getFunctions(firebaseApp);
if(window.location.origin.includes("localhost") || window.location.origin.includes("127.0.0.1")){
    connectFunctionsEmulator(functions, "127.0.0.1", 5001)
}

const addMessageFunction = httpsCallable(functions, 'addmessage');
const getAllFunction = httpsCallable(functions, 'getall');
const getAllCloudPluginFunction = httpsCallable(functions, 'getallcloudplugin');
const getProCloudPluginFunction = httpsCallable(functions, 'getprocloudplugin');
const getFreeCloudPluginFunction = httpsCallable(functions, 'getfreecloudplugin');
const getOnePluginFunction = httpsCallable(functions, 'getoneplugin');
const addProductFunction = httpsCallable(functions, 'addproduct');
const deleteMessageFunction = httpsCallable(functions, 'deletemessage');
const updatePluginFunction = httpsCallable(functions, 'updateplugin');
const deleteImgFunction = httpsCallable(functions,'deleteimg')

export const createProduct = async (product) => {
    await addProductFunction(product)
        .then((result) => {
            return result.data
        })
        .catch((error) => {
            return error;
        })
};


export const addMessage = async (messageText) => {
    await addMessageFunction(messageText)
        .then((result) => {
            return result.data
        })
        .catch((error) => {
            return error;
        })
};

export const getAll = () =>
    getAllFunction().then(result => result.data.messages).catch(error => error);

export const getForJCP = (params) => {
    switch (params) {
        case 'free':
            return getFreeCloudPluginFunction()
                .then(result => result.data.messages)
                .catch(error => error);
        case 'pro':
            return getProCloudPluginFunction()
                .then(result => result.data.messages)
                .catch(error => error);
        default:
            return getAllCloudPluginFunction()
                .then(result => result.data.messages)
                .catch(error => error);
    }
};
export  const getOnePlugin = (id) =>
    getOnePluginFunction(id)
        .then(result => result.data.messages)
        .catch(error => error);

export const deleteMessage =  (pluginId) => {
    return deleteMessageFunction( pluginId )
        .then(result => result.data.messages)
        .catch(error => error);
};


export const updatePlugin =  (updatedPlugin) => {
    console.log("Running updatePlugin with data of ", updatedPlugin)

    return updatePluginFunction(updatedPlugin)
        .then(result => result.data.messages)
        .catch(error => error);
};

export const deleteImg = (deleteImage) => {
    console.log("Running deleteImg with data of ", deleteImage);

    return deleteImgFunction(deleteImage)
        .then(result => result.data.messages)
        .catch(error => error);
}
