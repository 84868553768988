import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {
    Container, TextField, Button, InputLabel, FormControl, NativeSelect, Typography, Box, Stack, SvgIcon,
    Grid, Breadcrumbs, Link, InputAdornment, FormLabel, Divider, RadioGroup, FormControlLabel, Radio
} from '@mui/material';
import {useQuery, useQueryClient} from 'react-query';
import {deleteImg, getOnePlugin, updatePlugin} from '../query/Query';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {Modal, Upload, Image} from "antd";
import {getImgUrl} from './JiraCloudPlugins'
import {getBase64, handleRemove, handleUpload} from './NewJiraCloudPlugin';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function UpdatePluginPage() {
    const pluginId = useLocation().pathname.replace('/UpdateJiraPlugin/:', '');
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [updatedPlugin, setUpdatedPlugin] = useState({imgList: {}});
    const [imageUrls, setImageUrls] = useState({})
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);

    // Fetch the plugin details using the useQuery hook
    const {isLoading, data: pluginDetails} = useQuery(['updatePlugin', pluginId], () =>
        getOnePlugin(pluginId), {enabled: !!pluginId})
    console.log("pluginDetails in update page is ", pluginDetails);

    const handleCancel = () => setPreviewVisible(false);
    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    }

    //to store image url from fetching
    useEffect(() => {
        if (!isLoading && pluginDetails) {
            console.log("pluginDetails is ", pluginDetails);
            const imageList = pluginDetails.formData.imgList;

            if (imageList) {
                const fetchImageUrls = async () => {
                    const updatedImgUrls = {};
                    await Promise.all(
                        Object.entries(imageList).map(async ([key, value]) => {
                            if (key && value) {
                                try {
                                    updatedImgUrls[key] = await getImgUrl(value);
                                } catch (error) {
                                    console.error("Error fetching image URL:", error);
                                    updatedImgUrls[key] = null; // Handle the error case
                                }
                            }
                        })
                    );
                    setImageUrls(updatedImgUrls);
                    console.log("imageUrls is ", updatedImgUrls);
                };
                fetchImageUrls();
            }
        }
    }, [pluginDetails, isLoading]);

    // Handle changes in the input fields
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        if (e.target.name === 'keyImage') {
            setUpdatedPlugin({...updatedPlugin, keyImg: e.target.value});
        } else {
            setUpdatedPlugin((prevPlugin) => ({
                ...prevPlugin,
                [name]: value,
            }))
        }
        console.log('updatedPlugin is', updatedPlugin)
    };
    const handleChange = ({file, fileList}) => {
        console.log("handleChange : File list ", fileList);//all files
        //console.log("handleChange : File ", file);//the last file

        if (file.status === 'done') {
            let url = file.xhr;
            let fileName = file.name;
            const tempFormData = updatedPlugin;
            tempFormData.imgList[fileName] = url
            setUpdatedPlugin(tempFormData)
        }
        setFileList(fileList);
    }

    // Handle the form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('updatedPlugin after submit: ', updatedPlugin);
        let tempData = {};
        if (updatedPlugin.pluginName)
            tempData["formData.pluginName"] = updatedPlugin.pluginName;
        if (updatedPlugin.description)
            tempData["formData.description"] = updatedPlugin.description;
        if (updatedPlugin.price)
            tempData["formData.price"] = updatedPlugin.price;
        if (updatedPlugin.scale)
            tempData["formData.scale"] = updatedPlugin.scale;
        if (updatedPlugin.imgList) {
            console.log(updatedPlugin.imgList);
            for (let [key, value] of Object.entries(updatedPlugin.imgList)) {
                const filename = key.replace(".", "-")
                tempData["formData.imgList." + filename] = value;
            }
        }
        if (updatedPlugin.keyImg)
            tempData["formData.keyImg"] = updatedPlugin.keyImg;
        if (updatedPlugin.versionList)
            tempData["formData.versionList"] = updatedPlugin.versionList;
        if (updatedPlugin.type)
            tempData["formData.type"] = updatedPlugin.type;
        if (Object.keys(tempData).length > 0) {
            updatePlugin({id: pluginId, ...tempData}).then(() =>
                queryClient.invalidateQueries(['updatePlugin', pluginId])
            );
        } else {
            console.log('No fields have been modified.');
        }
        navigate('/JiraCloudPlugins');
    };

    const deleteImage = (key) => {
        const tempsData = key;
        const pluginId = pluginDetails.id;
        try {
            deleteImg({pluginId, tempsData}).then(() => {
                queryClient.invalidateQueries(['updatePlugin', pluginId]);
            });
        } catch (error) {
            console.error('Error deleting plugin:', error);
        }
    };


    return (
        <Container sx={{minHeight: {sm: 300, md: 450}, paddingY: 2, alignItems: 'center', justifyContent: 'center'}}>
            {/*breadcrumbs*/}
            <Grid container direction={"row"} sx={{paddingX: 5, alignItems: "flex-start"}}>
                <Stack direction={"row"} role="presentation">
                    <Breadcrumbs aria-label="breadcrumb" sx={{flexGrow: 1, fontSize: 13}} color={'#9e9e9e'}>
                        <Link underline="hover" color="inherit" href="/">
                            All Plugins
                        </Link>
                        <Link underline="hover" color="inherit" href={"/JiraCloudPlugins"}>
                            Jira Cloud Plugins
                        </Link>
                        <Typography fontSize={13}>
                            Update Plugin
                        </Typography>
                    </Breadcrumbs>
                </Stack>
            </Grid>
            {/*main part of the page */}
            <Grid sx={{justifyItems: 'center', paddingY: 2, paddingX: 5, minWidth: {xs: 200, sm: 500}}}>
                {/*title*/}
                <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                    <h3>Updating Plugin</h3>
                    <SvgIcon sx={{paddingTop: 2}}>
                        <AutoFixHighIcon/>
                    </SvgIcon>
                </Stack>
                {/*form part*/}
                <Box component={'form'} onSubmit={handleSubmit}>
                    <div hidden> {/*hide id*/}
                        <TextField
                            label={'plugin id'}
                            name={'pluginId'}
                            variant="standard"
                            value={updatedPlugin.id || pluginDetails?.id}
                        />
                    </div>
                    {/*1st line for plugin name*/}
                    <FormControl>
                        <TextField
                            label="Plugin Name"
                            name="pluginName"
                            variant={'standard'}
                            color={"success"}
                            value={updatedPlugin.pluginName || pluginDetails?.formData.pluginName}
                            onChange={handleInputChange}
                            focused /*prevent the label to cover its value*/
                        />
                    </FormControl>
                    {/*2nd line for type and scale*/}
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={15} sx={{marginY: 2}}>
                        <FormControl sx={{minWidth: 200}}>
                            <InputLabel id="select-label-type" style={{fontSize: 16, color: 'green'}}>Type</InputLabel>
                            <NativeSelect
                                labelid="select-label-type"
                                name={'type'}
                                label="Type"
                                value={updatedPlugin.type}
                                onChange={handleInputChange}
                                required
                                variant={"standard"}
                                style={{fontSize: 12}}
                                defaultValue={pluginDetails?.formData.type}
                            >
                                <option value={'Jira Cloud Plugin'}>Jira Cloud Plugin</option>
                                <option value={'Jira Software Plugin'}>Jira Software Plugin</option>
                            </NativeSelect>
                        </FormControl>
                        <FormControl sx={{minWidth: 200}}>
                            <InputLabel id="select-label-scale"
                                        style={{fontSize: 16, color: 'green'}}>Scale</InputLabel>
                            <NativeSelect
                                labelid="select-label-scale"
                                name={'scale'}
                                label="Scale"
                                value={updatedPlugin.scale || pluginDetails?.formData.scale}
                                onChange={handleInputChange}
                                required
                                variant={"standard"}
                                style={{fontSize: 12}}
                                defaultValue={pluginDetails?.formData.scale}
                            >
                                <option value={'free'}>Free</option>
                                <option value={'pro'}>Pro</option>
                            </NativeSelect>
                        </FormControl>
                    </Stack>
                    {/*3rd line for price and version*/}
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={15} sx={{marginY: 2}}>
                        <FormControl sx={{minWidth: 200}}>
                            <TextField
                                label="Price"
                                name="price"
                                variant="standard"
                                value={updatedPlugin.price || pluginDetails?.formData.price}
                                onChange={handleInputChange}
                                focused
                                color={"success"}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    style: {fontSize: 12}
                                }}
                            />
                        </FormControl>
                        <FormControl sx={{minWidth: 150}}>
                            <TextField
                                label="Version"
                                name="versionList"
                                variant="standard"
                                value={updatedPlugin.versionList || pluginDetails?.formData.versionList}
                                onChange={handleInputChange}
                                focused
                                color={"success"}
                                InputLabelProps={{
                                    style: {
                                        fontSize: 16
                                    },
                                }}
                            />
                        </FormControl>
                    </Stack>

                    {/*4th line for imageList*/}
                    <Stack marginY={2}>
                        <FormLabel style={{fontSize: 13, color: 'green'}} variant="standard">Current images:</FormLabel>
                        <Stack direction={'row'} alignItems={'center'} sx={{height: '100%'}}>
                            <Image.PreviewGroup

                                preview={{
                                    onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                }}>
                                {Object.keys(imageUrls).length > 0
                                    ?
                                    (Object.keys(imageUrls).map((key) => {
                                        return (
                                            <Box key={key}>
                                                <Image src={imageUrls[key]} alt={imageUrls[key]}
                                                       style={{height: 100, width: 'auto', margin: 15}}/>
                                                <Typography
                                                    sx={{borderBottom: 'solid', borderColor: 'green', marginX: 1}}
                                                    align={'center'}>• {key}
                                                    <Button onClick={() => deleteImage(key)}>
                                                        <DeleteForeverIcon color={'success'}/>
                                                    </Button>
                                                </Typography>

                                            </Box>)
                                    }))
                                    : null}
                            </Image.PreviewGroup>
                            <Divider orientation={'vertical'} flexItem sx={{m: 2, borderColor: '#e0e0e0'}}/>
                            <FormControl sx={{minWidth: 150, marginLeft: 2}}>
                                <Upload
                                    name='imgList' //上会后台的图片文件参数名
                                    listType="picture-card"
                                    fileList={fileList} //the images that have been uploaded
                                    onPreview={handlePreview}
                                    customRequest={handleUpload}
                                    onChange={handleChange}
                                    onRemove={handleRemove}
                                    value={updatedPlugin.imgList}>
                                    {(Object.keys(imageUrls).length + fileList.length) >= 5 ? null :
                                        (<div>
                                            <AddPhotoAlternateIcon color="success"/>
                                            <Typography>Add image</Typography>
                                        </div>)
                                    }
                                </Upload>
                                <Modal open={previewVisible} footer={null} onCancel={handleCancel}>
                                    <img alt="example" style={{width: '100%'}} src={previewImage}/>
                                </Modal>
                            </FormControl>
                        </Stack>
                    </Stack>
                    {/*5th line for key image*/}
                    <Stack>
                        {pluginDetails?.formData.keyImg && (
                            <FormControl component="fieldset" required={true}>
                                <FormLabel component="legend" style={{fontSize: 13, color: 'green'}} required>
                                    Select an image as a key image
                                </FormLabel>
                                <RadioGroup
                                    aria-label="keyImage"
                                    name="keyImage"
                                    defaultValue={pluginDetails.formData.keyImg}
                                    value={updatedPlugin.keyImg}
                                    onChange={handleInputChange}
                                >
                                    {Object.keys(pluginDetails.formData.imgList).map((item) => (
                                        <FormControlLabel
                                            key={item}
                                            value={pluginDetails.formData.imgList[item]}
                                            control={<Radio/>}
                                            label={item}
                                        />
                                    ))}
                                    {fileList.map((item) => (
                                        <FormControlLabel
                                            key={item.name}
                                            value={item.xhr}
                                            control={<Radio/>}
                                            label={item.name.replace(".", "-")}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        )}
                    </Stack>
                    {/*6th line for key description*/}
                    <Stack direction={{xs: 'column', sm: 'row'}} sx={{marginY: 2}}>
                        <TextField
                            label="Description"
                            sx={{width: 500}}
                            multiline
                            rows={3}
                            focused={true}
                            color={"success"}
                            name="description"
                            placeholder="Description:"
                            value={updatedPlugin.description || pluginDetails?.formData.description}
                            onChange={handleInputChange}
                            InputLabelProps={{
                                style: {
                                    fontSize: 16
                                },
                            }}
                        />
                    </Stack>

                    <Button variant="contained" color={'success'} type="submit">
                        Update
                    </Button>
                </Box>
            </Grid>
        </Container>
    );
}

export default UpdatePluginPage;
