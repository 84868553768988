import React from 'react';
import {
    Box,
    Link,
    Container,
    SvgIcon,
    Grid,
    Stack,
    Divider,
    Typography,
    Button,
    Dialog,
    DialogContentText, DialogTitle, DialogContent, DialogActions
} from "@mui/material";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import HomeIcon from '@mui/icons-material/Home';
import header from '../images/header.png';

function Footer() {
    const [openT, setOpenT] = React.useState(false);
    const [openP, setOpenP] = React.useState(false);
    //const [openL, setOpenL] = React.useState(false);

    const handleClickOpen = (key) => () => {
        switch (key) {
            case 'Terms':
                setOpenT(true);
                break;
            // case 'License':
            //     setOpenL(true);
            //     break;
            default:
                setOpenP(true);
                break;
        }
    };
    const handleClose = () => {
        setOpenT(false);
        setOpenP(false);
        //setOpenL(false);

    };
    const isOpen = openP || openT;
    //    const isOpen = openP || openT || openL;
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
    }, [isOpen]);

    const textP = (
        <DialogContentText
            id={'policy-description'}
            ref={descriptionElementRef}
            tabIndex={-1}
        >
            <Typography fontSize={13} fontStyle={'italic'}>Last Updated: 11 sept, 2023</Typography>
            <Divider sx={{m: 1}}/>
            <Typography fontSize={13} marginY={1}>PluginCraft / Artisan de Plugiciel is committed to protecting the
                privacy and security of your personal information. This Privacy Policy outlines how we collect, use, and
                safeguard your information when you use our website, which offers cloud plugins for Jira. By using our
                Website, you consent to the practices described in this Privacy Policy.</Typography>
            <dl style={{fontSize: 13}}>
                <dt style={{fontWeight: 'bold'}}>1. Information We Collect</dt>
                <dd>1.1 Personal Information: We may collect personal information, such as your name and email address,
                    when you make register or contact us for support.
                </dd>
                <dd>1.2 Non-Personal Information: We may also collect non-personal information, such as your IP address,
                    browser type, and device information, for analytics and security purposes.
                </dd>
                <dt style={{fontWeight: 'bold'}}>2. How We Use Your Information</dt>
                <dd>2.1 To Provide Services: We use your personal information to provide customer support.
                </dd>
                <dd>2.2 Marketing: With your consent, we may use your email address to send you promotional materials
                    about our products and services. You can opt-out of these communications at any time.
                </dd>
                <dd>2.3 Analytics: We may use non-personal information for analytics to improve our Website's
                    performance and user experience.
                </dd>
                <dt style={{fontWeight: 'bold'}}>3. Data Sharing</dt>
                <dd>We do not sell or rent your personal information to third parties.
                </dd>
                <dt style={{fontWeight: 'bold'}}>4. Security</dt>
                <dd>We take reasonable steps to protect your personal information from unauthorized access, disclosure,
                    alteration, or destruction. However, no data transmission over the internet or data storage system
                    is 100% secure, so we cannot guarantee the absolute security of your information but we use SSL
                    certificate to encrypt all transmission.
                </dd>
                <dt style={{fontWeight: 'bold'}}>5. Cookies</dt>
                <dd>Our Website may use cookies and similar tracking technologies to enhance your browsing experience.
                    You can manage your cookie preferences through your browser settings.
                </dd>
                <dt style={{fontWeight: 'bold'}}>6. Your Choices</dt>
                <dd>You have the following rights regarding your personal information:</dd>
                <dd>6.1 Access: You can request access to the personal information we hold about you.</dd>
                <dd>6.2 Correction: You can request corrections to inaccurate or incomplete information.</dd>
                <dd>6.3 Deletion: You can request the deletion of your personal information, subject to legal
                    obligations.
                </dd>
                <dd>6.4 Opt-Out: You can opt-out of marketing communications at any time by following the instructions
                    provided in the communication.
                </dd>
                <dt style={{fontWeight: 'bold'}}>7. Changes to this Privacy Policy</dt>
                <dd>We may update this Privacy Policy to reflect changes in our practices or for other operational,
                    legal, or regulatory reasons. Any changes will be posted on this page, and the "Last Updated" date
                    will be revised accordingly.
                </dd>
                <dt style={{fontWeight: 'bold'}}>8. Contact Us</dt>
                <dd>If you have any questions or concerns about this Privacy Policy or our data practices, please
                    contact us at:
                </dd>
                <dd>-- PluginCraft / Artisan de Plugiciel</dd>
                <dd> -- plugincraft100@gmail.com</dd>
            </dl>
        </DialogContentText>
    )

    const textT = (
        <DialogContentText
            id={'terms-description'}
            ref={descriptionElementRef}
            tabIndex={-1}
        >
            <Typography fontSize={13} fontStyle={'italic'}>Last Updated: 11 sept, 2023</Typography>
            <Divider sx={{m: 1}}/>
            <Typography fontSize={13} marginY={1}>These Terms of Service ("Terms") govern your use of our website operated by PluginCraft / Artisan de Plugiciel. By accessing or using the Website, you agree to comply with these Terms. If you do not agree to these Terms, please do not use the Website.</Typography>
            <dl style={{ fontSize: 13 }}>
                <dt>1. User Responsibilities</dt>
                <dd>You agree to use the Website in accordance with all applicable laws and regulations. You are solely responsible for any content you post or transmit on the Website and for your interactions with other users.</dd>

                <dt>2. Products and Services</dt>
                <dd>
                    <h4>2.1 Description</h4>
                    <p>We offer plugins for Jira on our Website. The descriptions of our products and services are subject to change without notice.</p>

                    <h4>2.2 Pricing</h4>
                    <p>Product prices are listed on the Atlassian Marketplace. We reserve the right to change prices at any time. Any changes will be posted on the Atlassian Marketplace.</p>
                </dd>

                <dt>3. Orders and Delivery</dt>
                <dd>
                    <h4>3.1 Ordering on Atlassian Marketplace</h4>
                    <p>Ordering is only available through the Atlassian Marketplace</p>

                    <h4>3.2 Delivery</h4>
                    <p>Delivery is only available through the Atlassian Marketplace</p>
                </dd>

                <dt>4. Refunds and Returns</dt>
                <dd>
                    <h4>4.1 Refunds</h4>
                    <p>Refunds for our plugins are subject to the refund policy of the Atlassian Marketplace. As a seller on the Atlassian Marketplace, we adhere to their refund policy, and any requests for refunds must be initiated through the Atlassian Marketplace platform.</p>

                    <h4>4.2 Contact Atlassian Support</h4>
                    <p>If you have any concerns or issues regarding refunds or returns, please contact Atlassian Marketplace Support directly. They will assist you in accordance with their policies and procedures.</p>
                </dd>

                <dt>5. Intellectual Property</dt>
                <dd>
                    <h4>5.1 Ownership</h4>
                    <p>All content on this Website, including but not limited to text, graphics, logos, and plugins, is owned or licensed by the Company and is protected by copyright and other intellectual property laws.</p>

                    <h4>5.2 Limited License</h4>
                    <p>We grant you a limited, non-exclusive, revocable license to use the plugins for Jira purchased through the Atlassian Marketplace for your own use. You may not modify, distribute, or otherwise use the plugins without our written permission.</p>
                </dd>

                <dt>6. Termination</dt>
                <dd>We reserve the right to terminate or suspend your access to the Website at our sole discretion, without notice, for any reason, including, but not limited to, violation of these Terms or for conduct that we believe may harm the Website or other users.</dd>

                <dt>7. Dispute Resolution</dt>
                <dd>Any disputes arising from your use of the Website will be governed by the laws of Quebec Canada. You agree to submit to the exclusive jurisdiction of the courts located within Quebec, Canada for the resolution of any dispute.</dd>

                <dt>8. Changes to Terms</dt>
                <dd>We may modify these Terms at any time, and such modifications will be effective immediately upon posting on the Website. You are responsible for regularly reviewing these Terms. Your continued use of the Website after changes will constitute your acceptance of such changes.</dd>

                <dt>9. Contact Information</dt>
                <dd>If you have any questions or concerns regarding these Terms, please contact us at: plugincraft100@gmail.com</dd>
            </dl>
        </DialogContentText>
    )
    return (
        <Box
            sx={{
                width: "100%",
                height: "auto",
                backgroundImage: `url(${header})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                paddingTop: "1rem",
                paddingBottom: "1rem",
                mt: 0.1
            }}
        >
            <Container maxWidth="lg">
                <Grid container direction={"column"} alignItems="center" color={'#f5f5f5'}>
                    <Stack direction={'row'} spacing={{xs: 1, sm: 8}} alignItems="center"
                           divider={<Divider orientation="vertical" flexItem sx={{my: 2, borderColor: '#212121'}}/>}>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={{xs: 1, sm: 20}} alignItems="flex-start">
                            {/*<Stack>
                                <Typography variant="h6" mb={1}>
                                    Company
                                </Typography>
                                <Link href={'#'} color={'#f5f5f5'} fontSize={10}>About Us</Link>*/}
                                {/*<Link href={'#'} color={'#f5f5f5'} fontSize={10}>Site Map</Link>
                            </Stack>*/}
                            <Stack>
                                <Typography variant="h6" mb={1}>
                                    Legal
                                </Typography>
                                <Stack >
                                    <Button
                                        onClick={handleClickOpen('Terms')}
                                        sx={{fontSize: 8, color: '#f5f5f5', padding: 0,justifyContent:'left'}}
                                        size={'small'}
                                    >
                                        Terms of service
                                    </Button>
                                    <Button
                                        onClick={handleClickOpen('Privacy')}
                                        sx={{fontSize: 8, color: '#f5f5f5', padding: 0,justifyContent:'left'}}
                                        size={'small'}
                                    >
                                        Privacy policy
                                    </Button>
{/*                                    <Button onClick={handleClickOpen('License')}
                                            sx={{fontSize: 8, color: '#f5f5f5', padding: 0}}
                                            size={'small'}>Licenses</Button>*/}
                                    <Dialog
                                        open={openP}
                                        onClose={handleClose}
                                        scroll={'paper'}
                                        aria-labelledby="policy-title"
                                        aria-describedby="policy-description"
                                    >
                                        <DialogTitle id="policy-title">Privacy Policy</DialogTitle>
                                        <DialogContent>
                                            {textP}
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleClose}>Close</Button>
                                        </DialogActions>
                                    </Dialog>
                                    <Dialog
                                        open={openT}
                                        onClose={handleClose}
                                        scroll={'paper'}
                                        aria-labelledby="terms-title"
                                        aria-describedby="terms-description"
                                    >
                                        <DialogTitle id="terms-title">Terms of service</DialogTitle>
                                        <DialogContent>
                                            {textT}
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleClose}>Close</Button>
                                        </DialogActions>
                                    </Dialog>
                                    {/*<Dialog
                                        open={openL}
                                        onClose={handleClose}
                                        scroll={'paper'}
                                        aria-labelledby="license-title"
                                        aria-describedby="license-description"
                                    >
                                        <DialogTitle id="license-title">Licenses</DialogTitle>
                                        <DialogContent>
                                            {textL}
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleClose}>Close</Button>
                                        </DialogActions>
                                    </Dialog>*/}
                                </Stack>
                            </Stack>
                            {/*<Stack>
                                <Typography variant="h6" mb={1}>
                                    Follow
                                </Typography>
                                <Stack direction={'row'} spacing={0}>
                                    <IconButton size={'small'} edge={'start'} color={'inherit'}
                                                aria-label={'client-avatar'}>
                                        <TwitterIcon/>
                                    </IconButton>
                                    <IconButton size={'small'} edge={'start'} color={'inherit'}
                                                aria-label={'client-avatar'}>
                                        <FacebookIcon/>
                                    </IconButton>
                                    <IconButton size={'small'} edge={'start'} color={'inherit'}
                                                aria-label={'client-avatar'}>
                                        <LinkedInIcon/>
                                    </IconButton>
                                </Stack>
                            </Stack>*/}
                        </Stack>

                        <Stack direction={"column"} alignItems={"flex-end"} justifyContent={"flex-end"}>
                            <Stack mb={1}>
                                <Typography variant="h6">
                                    Contact Us
                                </Typography>
                            </Stack>
                            <Stack>
                                <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
                                    <Link color={'#f5f5f5'} fontSize={10} href={'mailto:plugincraft100@hotmail.com'}
                                          underline={'none'}>
                                        Support@plugincraft.ca
                                    </Link>
                                    <SvgIcon sx={{fontSize: 16}}>
                                        <AlternateEmailIcon/>
                                    </SvgIcon>
                                </Stack>
                                <Stack direction={"row"} spacing={2} alignItems={"flex-end"}
                                       justifyContent={"flex-end"}>
                                    <Typography fontSize={10}>
                                        Ste-Therese, Quebec, Canada
                                    </Typography>
                                    <SvgIcon sx={{fontSize: 16}}>
                                        <HomeIcon/>
                                    </SvgIcon>
                                </Stack>

                            </Stack>
                        </Stack>
                    </Stack>

                    <Divider sx={{my: 1, borderColor: '#424242'}} orientation="horizontal" flexItem/>
                    <Stack xs={12} fontSize={10}>
                        <Typography color="whitesmoke" variant="">
                            &copy;  {` ${new Date().getFullYear()} PluginCraft enr.  All Rights Reserved.  `}
                        </Typography>
                    </Stack>
                </Grid>
            </Container>
        </Box>
    );
}

export default Footer;