import React from 'react';
import {Typography, Box, Container, Stack} from '@mui/material';


import {List } from "antd";

function PluginVersions() {

    const data =[
        {
            title: '•1.0.0 version',
            description:'Released in 13th Sept, 2023;'
        },
        {
            title: '• 1.0.1',
            description:'....'
        },
    ]



    return (
        <Container>
            <Box sx={{
                mb: 2,
                minHeight: 200,
                paddingLeft: 2
            }}>
                <Stack direction={'row'} paddingX={2} alignItems={'center'} spacing={1}>
                    <img src={require('../images/issue-logo.jpg')} alt="issue-logo" style={{width:100,height:"auto"}} />
                    <Typography fontSize={20} >
                        Version History:
                    </Typography>
                </Stack>

                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item, index) => (
                        <List.Item >
                            <List.Item.Meta
                                key={item}
                                style={{paddingLeft:10}}
                                title={<a href={"#feature" + (index+1)} >{item.title}</a>}
                                description={<Typography fontSize={13} href={"#feature" + (index+1)} >{item.description}</Typography>}
                            />
                        </List.Item>
                    )}
                />
            </Box>

            {/*add more details here if needed
            <Typography id={'feature1'} >more details </Typography>
            */}
        </Container>
    );
}

export default PluginVersions;