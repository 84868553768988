import {
    Stack, Box, Container, Grid,
    TextField,
    Button,
    Alert,
    AlertTitle,
    Link,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    InputAdornment,
    FormHelperText,
    SvgIcon,
    Typography,
    FormLabel, Radio, RadioGroup, FormControlLabel, Breadcrumbs
} from "@mui/material";
import {addMessage} from "../query/Query";
import React, {useState} from 'react';
import {useQueryClient} from "react-query";
import DrawIcon from '@mui/icons-material/Draw';
import UploadIcon from '@mui/icons-material/Upload';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {Modal, Upload} from "antd";
import {firebaseStorage} from "../Firebase";
import {connectStorageEmulator, ref, uploadBytes, deleteObject} from "firebase/storage";


export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
export const handleRemove = (file) =>{
    console.log("handleRemove : File ", file);
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
        connectStorageEmulator(firebaseStorage, "localhost", 9199);
    }
    const fileName = file.name.replace(".","-")
    const storageRef = ref(firebaseStorage, `images/pluginOverview/${fileName}`)
    try{
        deleteObject(storageRef).then(() => {
            return true;
        });
    } catch(e){
        console.log("error in deleting image",e)
        return false;
    }
}
export const handleUpload = ({onError, onSuccess, file}) => {
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
        connectStorageEmulator(firebaseStorage, "localhost", 9199);
    }
    const fileName = file.name.replace(".","-")
    const storageRef = ref(firebaseStorage, `images/pluginOverview/${fileName}`)
    const metadata = {
        contentType: 'image/'+file.type,
    }
    try{
        uploadBytes(storageRef, file, metadata).then((snapshot) => {
            onSuccess(null, snapshot.ref.fullPath)
        });
    }catch(e){
        console.log("error in uploading image",e)
        onError(null, e);
    }
}

function NewJiraCloudPlugin() {
    const queryClient = useQueryClient()
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        pluginName: '',
        type: '',
        description: '',
        scale: '',
        price: '',
        imgList: {},
        keyImg: '',
        versionList:''
    });
//for image upload
    const [previewVisible,setPreviewVisible] =useState(false);
    const [previewImage,setPreviewImage] = useState('');
    const [fileList,setFileList] = useState([]);

//when upload image, fileList will be filled with info of image
    const handleChange = ({file, fileList }) => {
        console.log ("handleChange : File list ", fileList);//all files
        //console.log("handleChange : File ", file);//the last file

        if (file.status === 'done') {
            let url = file.xhr;
            let fileName = file.name.replace(".","-");
            const tempFormData = formData;
            tempFormData.imgList[fileName] = url
            setFormData(tempFormData)
        }
        setFileList(fileList);
    }

    const uploadButton = (
        <React.Fragment>
            <UploadIcon sx={{paddingRight:1}}/>
            <Typography sx={{}}>
                Upload
            </Typography>
        </React.Fragment>
    );
    //for image upload - end
     const handleCancel = () => setPreviewVisible(false);
     const  handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    }


    const handleInputChange = (e) => {
        if (e.target.name === 'keyImage') {
            setFormData({ ...formData, keyImg: e.target.value });
        }  else{
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        addMessage(formData).then(() =>
            queryClient.invalidateQueries({queryKey: "messages"})
        );
    };



    return (
        <Container sx={{minHeight: {sm: 300, md: 450},paddingY:2, alignItems: 'center', justifyContent: 'center'}} >
            <Grid container direction={"row"} sx={{paddingX: 5,alignItems: "flex-start"}}>
                <Stack direction={"row"} role="presentation">
                    <Breadcrumbs aria-label="breadcrumb" sx={{flexGrow: 1, fontSize: 13}} color={'#9e9e9e'}>
                        <Link underline="hover" color="inherit" href="/">
                            All Plugins
                        </Link>
                        <Link underline="hover" color="inherit" href={"/JiraCloudPlugins"}>
                            Jira Cloud Plugins
                        </Link>
                        <Typography fontSize={13}>
                            Add New Plugin
                        </Typography>
                    </Breadcrumbs>
                </Stack>
            </Grid>

            {isSubmitted ?
                (
            <Grid sx={{minWidth:{xs:200,sm:500},paddingY:8}}>
                <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    A new plugin data is saved!  — <strong>check it now! </strong>
                </Alert>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    paddingY:5,
                    typography: 'body1',
                    '& > :not(style) + :not(style)': {
                        ml: 2,
                    },
                }}>
                    <SvgIcon >
                        <KeyboardDoubleArrowLeftIcon fontSize="large" />
                    </SvgIcon>
                    <Link color="inherit" href="./JiraCloudPlugins"  >To see plugin list</Link>
                    <Typography paddingX={2}> | </Typography>
                    <Link color="inherit" href="./NewJiraCloudPlugin"  >Add another plugin</Link>
                    <SvgIcon >
                        <KeyboardDoubleArrowRightIcon fontSize="large" />
                    </SvgIcon>
                </Box>

            </Grid> ) : (
            <Grid sx={{justifyItems: 'center', paddingY:2, paddingX:5, minWidth:{xs:200,sm:500}}}>
                <Stack direction={{xs: 'column', sm: 'row'}}  spacing={1}>
                    <h3 >Adding New Plugin</h3>
                    <SvgIcon sx={{  paddingTop:2}}>
                        <DrawIcon />
                    </SvgIcon>
                </Stack>

                <Box  component={'form'} onSubmit={handleSubmit} >
                    <div>
                        <TextField
                            label={"Plugin Name"}
                            name={"pluginName"}
                            variant={"standard"}
                            value={formData.pluginName}
                            onChange={handleInputChange}
                            required
                            InputLabelProps={{style: {fontSize: 12}}}
                        />
                    </div>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={15}  sx={{marginY:2}}>
                        <FormControl sx={{minWidth: 200}}  >
                            <InputLabel id="select-label-type" style={{ fontSize: 12 }}>Type</InputLabel>
                            <Select
                                labelId="select-label-type"
                                name={'type'}
                                label="Type"
                                value={formData.type}
                                onChange={handleInputChange}
                                required
                                variant={"standard"}
                                style={{ fontSize: 12 }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={'Jira Cloud Plugin'}>Jira Cloud Plugin</MenuItem>
                                <MenuItem value={'Jira Software Plugin'}>Jira Software Plugin</MenuItem>
                            </Select>
                            <FormHelperText>Select its type</FormHelperText>
                        </FormControl>

                        <FormControl sx={{ minWidth: 200 }} >
                            <InputLabel id="select-label-scale" style={{ fontSize: 12 }}>Scale</InputLabel>
                            <Select
                                labelId="select-label-scale"
                                name={'scale'}
                                label="Scale"
                                value={formData.scale}
                                onChange={handleInputChange}
                                required
                                variant={"standard"}
                                style={{ fontSize: 12 }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={'free'}>Free</MenuItem>
                                <MenuItem value={'pro'}>Pro</MenuItem>
                            </Select>
                            <FormHelperText>Select its using scale</FormHelperText>
                        </FormControl>
                    </Stack>

                    {/*todo: modify type of price to number, to see https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries*/}
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={15} sx={{marginY:2}} >
                        <FormControl sx={{minWidth: 200}}>
                            <TextField
                                label="Price"
                                name="price"
                                variant="standard"
                                value={formData.price}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    style: {fontSize: 12}
                                }}
                            />
                        </FormControl>
                        {/*todo: modify type of version to array*/}
                        <FormControl  sx={{minWidth: 150}}>
                            <TextField
                                label="Version"
                                name="versionList"
                                variant="standard"
                                value={formData.versionList}
                                onChange={handleInputChange}
                                InputLabelProps={{
                                    style: {
                                        fontSize: 12
                                    },
                                }}
                            />
                        </FormControl>
                    </Stack>

                    <Stack>
                        <FormLabel sx={{marginY:1}} required={true} variant="standard">Images</FormLabel>
                        <FormControl sx={{ minWidth: 150 }} >
                            <Upload
                                name='imgList' //上会后台的图片文件参数名
                                listType="picture-card"
                                fileList={fileList} //the images that have been uploaded
                                onPreview={handlePreview}
                                customRequest={handleUpload}
                                onChange={handleChange}
                                onRemove={handleRemove}
                                value={formData.imgList}>
                                {fileList.length >= 5 ? null : uploadButton}
                            </Upload>
                            <Modal open={previewVisible} footer={null} onCancel={handleCancel}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </FormControl>
                    </Stack>

                    <Stack>
                        {fileList.length !== 0 && (
                            <FormControl component="fieldset" required={true}>
                                <FormLabel component="legend" style={{ fontSize: 12 }} required>
                                    Select an image as a overview image shown in the plugin list
                                </FormLabel>
                                <RadioGroup
                                    aria-label="keyImage"
                                    defaultValue={fileList[0].name}
                                    name="keyImage"
                                    value={formData.keyImg}
                                    onChange={handleInputChange}
                                >
                                    {fileList.map((item) => (
                                        <FormControlLabel
                                            key={item.name}
                                            value={item.xhr}
                                            control={<Radio />}
                                            label={item.name.replace(".","-")}
                                        />
                                    ))}
                                </RadioGroup>

                                {/*todo:add a container to enter content for image
                                <Input value={imgContent} name={'imgContent'} onChange={handleInputChange} />*/}
                            </FormControl>
                        )}
                    </Stack>


                    <Stack direction={{xs: 'column', sm: 'row'}} sx={{marginY:2}} >
                        <TextField
                            sx={{width:500}}
                            multiline
                            rows={3}
                            name="description"
                            placeholder="Description:"
                            value={formData.description}
                            onChange={handleInputChange}
                            InputLabelProps={{
                                style: {
                                    fontSize: 12
                                },
                            }}
                        />
                    </Stack>
                    <Button variant="outlined" type="submit">
                        Submit
                    </Button>
                </Box>
            </Grid>
            )}
        </Container>
    );
}

export default NewJiraCloudPlugin