import React from 'react';
import {
    Typography,
    Box,
    Container,
    Stack,
    ListItem,
    ListItemText,
    Grid
} from '@mui/material';
import {List, Image, FloatButton} from "antd";
import {useQuery} from "react-query";
import {getOnePlugin} from "../query/Query";

function PluginUsage(pluginId) {

    const id = pluginId.pluginId;
    const {data: pluginDetails} = useQuery(['messages', id], () => getOnePlugin(id), {enabled: !!pluginId})

    const usageText = ['• Link Type Visualization', '• Configurable Per-Project Visibility (For Jira Administrator)', '']
    const featureText = [
        'When managing complex projects with numerous interlinked issues, gaining a clear understanding of the relationships between them is essential. For instance, if an issue serves as a blocker for a dozen other issues, Issue Links Overview for Jira enables you to instantly recognize that it indeed blocks precisely 12 issues, streamlining your decision-making process.'
    ]
    const stepsContext = [
        {
            step1: '1. Within an issue, at the top, locate the "Issue Links Overview" icon.',
            step2: '2. Click on the icon, and the panel will promptly display a comprehensive overview of all linked details.',
        },
        {
            step1: '1. From your ‘JIRA SETTINGS’ go into the ‘Apps’ tab. Then select the ‘Issue Link Overview’ in the side Panel.',
            step2: '2. You can select which project have this feature enabled. Click on submit to save your change.'
        }
    ]
    const imgAndContext = [
        {
            'step1.png': 'Access to links overview',
            'step2.png': 'Overview of all issue links'
        },
        {
            'step3.png': 'Access to Configuration page',
            'step4.png': 'Customize the settings and manage project statues'
        }
    ]

    return (
        <Container id="top">
            <Box sx={{
                mb: 2,
                minHeight: 200,
                paddingLeft: 2,
            }}>
                {/*first stack for logo and plugin name*/}
                <Stack direction={'row'} paddingX={2} alignItems={'center'} spacing={1}>
                    <img src={require('../images/issue-logo.jpg')} alt="issue-logo"
                         style={{width: 100, height: "auto"}}/>
                    <Stack>
                        <Typography fontSize={20}>
                            Usage Examples
                        </Typography>
                        <Typography fontSize={12}>
                            {pluginDetails?.formData?.pluginName}&nbsp;{pluginDetails?.formData?.versionList}
                        </Typography>
                    </Stack>
                </Stack>
                {/* list of features*/}
                <List
                    itemLayout="horizontal"
                    dataSource={usageText}
                    renderItem={(item, index) => (
                        <List.Item >
                            <List.Item.Meta
                                key={item}
                                style={{paddingLeft: 10}}
                                title={<a href={"#feature" + (index)}>{item}</a>}
                            />
                        </List.Item>
                    )}
                />
            </Box>

            <Box sx={{mb: 2, minHeight: 400, paddingLeft: 2}}>
                {stepsContext.map((item, index) => {
                        return (
                            <Grid key={'step' + index}>
                                <Stack alignItems={'start'}>
                                    <Typography id={'feature' + index} paddingY={1}> &nbsp; </Typography>
                                    <Typography fontSize={20}
                                                paddingY={2}> >>>> {usageText[index].substring(1)}</Typography>
                                    <Typography fontSize={13}>
                                        {featureText[index]}
                                    </Typography>
                                    <Typography fontSize={15} paddingTop={3}>
                                        How to Activate it:
                                    </Typography>
                                    {Object.values(stepsContext[index]).map((step, stepIndex) => (
                                        <List>
                                            <ListItem style={{fontSize: 10}}>
                                                <ListItemText key={stepIndex} primary={step}/>
                                            </ListItem>
                                            <Image
                                                src={require(`../images/${Object.keys(imgAndContext[index])[stepIndex]}`)}
                                                alt={Object.keys(imgAndContext[index])[stepIndex]}
                                                style={{height: 'auto', width: 350, marginLeft: 50, marginTop: 10}}
                                            />
                                            <Typography
                                                fontSize={12}
                                                fontStyle={'italic'}
                                                marginLeft={15}
                                                marginY={1}
                                            >
                                                {Object.values(imgAndContext[index])[stepIndex]}
                                            </Typography>
                                        </List>
                                    ))}
                                </Stack>
                            </Grid>
                        )
                    }
                )}
            </Box>
            <FloatButton.BackTop shape={'square'} description={'Top'}/>
        </Container>
    );
}

export default PluginUsage;