import React, {useEffect, useState} from 'react';
import {
    Typography,
    Box,
    Container,
    Stack,
} from '@mui/material';
import {useQuery} from "react-query";
import {getOnePlugin} from "../query/Query";
import {connectStorageEmulator, getDownloadURL, getStorage, ref} from "firebase/storage";
import {firebaseStorage} from "../Firebase";
import {Image, FloatButton} from "antd";

function PluginOverview(pluginId) {

    const id = pluginId.pluginId;
    const {
        isLoading: isLoadingPluginDetails,
        data: pluginDetails
    } = useQuery(['messages', id], () => getOnePlugin(id), {enabled: !!pluginId})
    const [imageUrls, setImageUrls] = useState({})

    const imgContext = [
        'Effortlessly view link types, their respective link counts, and a detailed list of associated issues.',
        'Tailor the plugin\'s visibility to suit your project-specific needs.',
    ]// todo: need to add context for each image automatically when complete the modification of imgList item

    const keyFeatures = [
        '• Link Type Visualization', '• Configurable Per-Project Visibility'
    ]

    useEffect(() => {
        if (!isLoadingPluginDetails && pluginDetails) {
            console.log("Message data is ", pluginDetails, isLoadingPluginDetails);
            const imageList = pluginDetails.formData.imgList;

            if (imageList) {
                const fetchImageUrls = async () => {
                    const updatedImageUrls = {};

                    await Promise.all(
                        Object.entries(imageList).map(async ([key, value]) => {
                            if (key && value) {
                                try {
                                    if (pluginDetails.formData.keyImg.split("pluginOverview/").pop() === key) {
                                        console.log('this keyImage is in imgList', key);
                                    } else {
                                        updatedImageUrls[key] = await getImgUrl(value);
                                    }

                                } catch (error) {
                                    console.error("Error fetching image URL:", error);
                                    updatedImageUrls[key] = null; // Handle the error case
                                }
                            }
                        })
                    );
                    setImageUrls(updatedImageUrls);
                    console.log("imageUrls is ", updatedImageUrls);
                };
                fetchImageUrls();
            }
        }
    }, [pluginDetails, isLoadingPluginDetails]);

    const getImgUrl = async (img) => {
        const storage = getStorage();
        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            connectStorageEmulator(firebaseStorage, "localhost", 9199);
        }
        const starsRef = ref(storage, img);
        try {
            return await getDownloadURL(starsRef);
        } catch (err) {
            console.log("err is ", err);
            return null;
        }
    };

    return (
        <Container>
            <Stack direction={'row'} paddingX={4} alignItems={'center'} spacing={1}>
                <img src={require('../images/issue-logo.jpg')} alt="issue-logo" style={{width: 90, height: "auto"}}/>
                <Typography fontSize={20}>
                    {pluginDetails?.formData?.pluginName}
                </Typography>
                <Typography fontSize={12}>
                    {pluginDetails?.formData?.versionList}
                </Typography>
            </Stack>
            <Typography fontSize={13} variant="subtitle2" color="text.secondary" paddingX={3} style={{textIndent: 20}}
                        paddingBottom={3}>
                Issue Links Overview for Jira is a powerful tool designed to enhance your Jira experience. It provides a
                comprehensive view of linked issues directly within the issue details view, offering quick and intuitive
                insights into the quantity of each link type. This overview panel can be easily enabled by project
                administrators.
            </Typography>
            <Typography fontWeight={'bold'} fontSize={13} variant="subtitle2" color="text.secondary" paddingX={3}
                        style={{textIndent: 20}} paddingBottom={1}>
                Key Features:
            </Typography>
            {keyFeatures.map((item) => (
                <Typography key={item} fontSize={13} variant="subtitle2" color="text.secondary" paddingX={4}
                            style={{textIndent: 20}} paddingBottom={1}>
                    {item}
                </Typography>
            ))}

            <Box sx={{
                mb: 2,
                minHeight: 400,
                paddingX: 4,
                borderTop: 'solid',
                borderColor: '#eeeeee'
            }}>
                {Object.keys(imageUrls).length > 0
                    ?
                    (Object.keys(imageUrls).map((key, index) => {
                        if (index % 2 === 0) {
                            return (
                                <Stack key={key} direction={'row'} spacing={10}
                                       sx={{height: 200, alignItems: 'center'}}>
                                    <Typography
                                        sx={{width: 250, justifyItems: 'center', padding: 3, paddingLeft: 6}}
                                        variant="subtitle2" color="text.secondary">{imgContext[index]}</Typography>
                                    <Image src={imageUrls[key]} alt={imageUrls[key]}
                                           style={{height: 'auto', width: 330}}/>
                                </Stack>
                            )
                        } else {
                            return (
                                <Stack key={key} direction={'row'} spacing={3} sx={{height: 200, alignItems: 'center'}}>
                                    <Image src={imageUrls[key]} alt={imageUrls[key]}
                                           style={{height: 'auto', width: 330}}/>
                                    <Typography sx={{width: 250, justifyItems: 'left', padding: 3, paddingLeft: 6}}
                                                variant="subtitle2"
                                                color="text.secondary">{imgContext[index]}</Typography>
                                </Stack>
                            )
                        }
                    }))
                    :
                    (<Stack>
                        <Typography>Loading...</Typography>
                    </Stack>
                    )}
                <FloatButton.BackTop shape={'square'} description={'Top'}/>
            </Box>
        </Container>
    );
}

export default PluginOverview;