import { initializeApp } from "firebase/app";
import {getStorage} from "firebase/storage";
import { getAuth } from "firebase/auth";
import {getFirestore} from 'firebase/firestore'



// https://firebase.google.com/docs/web/setup#available-libraries
const firebaseConfig = {
    apiKey: "AIzaSyBPeF-cfLbMKxdy0vOpO0o31zyDm3zBhng",
    authDomain: "plugincraft-e5e73.firebaseapp.com",
    projectId: "plugincraft-e5e73",
    storageBucket: "plugincraft-e5e73.appspot.com",
    messagingSenderId: "334549573397",
    appId: "1:334549573397:web:397f3c78b19fe8e9d7131c",
    measurementId: "G-1M8JSMCJGB"
};
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
export const firebaseStorage = getStorage(firebaseApp);
export  const  db = getFirestore(firebaseApp)

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(firebaseApp);