import React, {useEffect} from 'react';
import {
    Breadcrumbs,
    Container,
    Grid,
    Stack,
    Link,
    Typography,
    Box,
    List,
    ListItem,
    ListItemButton, ListItemIcon, ListItemText, Divider, Card, CardMedia, ImageList, Tabs, Tab, styled
} from "@mui/material";
import {useLocation } from 'react-router-dom'
import {getOnePlugin} from "../query/Query";
import {useQuery} from "react-query";
import PluginOverview from "../components/pluginOverview"
import PluginVersions from "../components/pluginVersions";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import PropTypes from 'prop-types';
import PluginUsage from "../components/pluginUsage";

//functions for tab and tab-panel
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}  />
))({
    "& .MuiTabs-indicator": {
        display: "flex",
        width: 0
    }
});

function JiraPluginPage() {
    const id = useLocation().pathname.replace('/JiraPluginPage/:','');

    const {isLoading: isLoadingPluginDetails, data: pluginDetails} = useQuery(['messages',id], () => getOnePlugin(id),{enabled: !!id})
    useEffect(() => {
        if(!isLoadingPluginDetails && pluginDetails) {
            console.log("Message data in pluginPage is ", pluginDetails, isLoadingPluginDetails)
        }
    },[pluginDetails, isLoadingPluginDetails])

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Container sx={{minHeight: {sm: 300, md: 450}, paddingTop: {sm:6,md:2}}}>
            <Grid container direction={"row"} sx={{paddingX: 5,alignItems: "flex-start"}}>
                <Stack direction={"row"} role="presentation">
                    <Breadcrumbs aria-label="breadcrumb" sx={{flexGrow: 1, fontSize: 13}} color={'#9e9e9e'}>
                        <Link underline="hover" color="inherit" href="/">
                            All Plugins
                        </Link>
                        <Link underline="hover" color="inherit" href={"/JiraCloudPlugins"}>
                            Jira Cloud Plugins
                        </Link>
                        <Typography fontSize={13}>
                            {pluginDetails ? pluginDetails.formData.pluginName: 'Jira Plugin Page'}
                        </Typography>
                    </Breadcrumbs>
                </Stack>
            </Grid>

            <Stack direction={"row"} paddingY={2} spacing={2} justifyContent={'center'}>
                <Box sx={{ width: '23%'}}>
                    <nav aria-label="plugin detail docs">
                        <List >
                            <ListItem sx={{padding:0}}>
                                <ListItemButton sx={{padding:0}}>
                                    <ImageList sx={{width:70}}>
                                        <img src={require('../images/atlassian_logo.png')} className="Atlassian-logo" alt="logo"  />
                                    </ImageList>
                                    <ListItemText primary={
                                        <Link underline="hover" color="inherit" href={"https://marketplace.atlassian.com/"}>
                                            <Typography fontSize={15}>Atlassian Marketplace</Typography>
                                        </Link>
                                    }>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <SupportAgentIcon sx={{ color: "#2196f3" }}/>
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <Link underline="hover" color="inherit" href={"https://plugincraft.atlassian.net/servicedesk/customer/portal/2"}>
                                            <Typography fontSize={15}>Supports</Typography>
                                        </Link>
                                    } />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </nav>
                    <Divider />
                    <nav aria-label="secondary mailbox folders">
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="#docs">
                                    <ListItemText primary={
                                        <React.Fragment>
                                            <Stack direction={'row'} spacing={4} >
                                                <DatasetLinkedIcon color={'primary'}/>
                                                <Typography sx={{ fontSize:16}}>{pluginDetails? pluginDetails.formData.pluginName : 'Jira Cloud Plugin'}</Typography>
                                            </Stack>
                                            <Box sx={{ flexGrow: 1, bg: 'background.paper', display: 'flex', height: 224 }} justifyContent={'right'}>
                                                <StyledTabs value={value} orientation="vertical"  onChange={handleChange} aria-label="nav tabs" >
                                                    <Tab label=">> Overview" {...a11yProps(0)} sx={{fontSize:12 }} />
                                                    <Tab label=">> usage" {...a11yProps(1)} sx={{fontSize:12 }} />
                                                    <Tab label=">> versions" {...a11yProps(2)} sx={{fontSize:12 }} />
                                                </StyledTabs>
                                            </Box>
                                        </React.Fragment>
                                    } />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </nav>
                </Box>

                <Stack sx={{ width: '75%'  }}>
                    <Card spacing={1} sx={{paddingTop: 2}}  >
                        <Box style={{position:"relative"}}>
                            <CardMedia
                                component="img"
                                sx={{ width: {sm:150,md:750}, height:{sm: 50,md:120} ,margin:'auto' }}
                                image={require('../images/img-background-logo.jpg')}/*todo: this image is for instance, it's needed to be formed automatically with its name*/
                                alt={'im'}
                            />
                            <img
                                src={require(pluginDetails?.formData.scale === 'free'?'../images/free-tap.png':'../images/pro-tap.png')}
                                alt={'freeOrPro'}
                                style={{position:'absolute',top:10,left:50, width:70,height:"auto"}}
                            />
                            <Typography
                                fontSize={35}
                                color={'orange'}
                                style={{position:"absolute", top:30,right:100}}>Issue Links Overview</Typography>
                            <Typography
                                fontSize={20}
                                color={'orange'}
                                style={{position:"absolute", top:75,right:120}}>For Jira</Typography>
                        </Box>
                        <TabPanel value={value} index={0} >
                            <PluginOverview pluginId = {id} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <PluginUsage pluginId = {id} />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <PluginVersions pluginId = {id} />:
                        </TabPanel>
                    </Card>
                </Stack>
            </Stack>
        </Container>
    );
}

export default JiraPluginPage;


