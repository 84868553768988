import React from "react";
import './App.css';
import {QueryClient, QueryClientProvider} from 'react-query'
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Stack} from '@mui/material';
import Header from "./components/header";
import Home from "./pages/Home"
import JiraCloudPlugins from "./pages/JiraCloudPlugins";
import JiraPluginPage from "./pages/JiraPluginPage;"
import NewJiraCloudPlugin from "./pages/NewJiraCloudPlugin";
import Footer from "./components/footer";
import {ReactQueryDevtools} from "react-query/devtools";
import SignIn from "./components/auth/signIn";
import SignUp from "./components/auth/signUp";
import UpdateJiraPlugin from "./pages/updateJiraPlugin";

const queryClient = new QueryClient()

function App() {

    return (
        <BrowserRouter>
            <Header/>
            <QueryClientProvider client={queryClient}>

                <Stack sx={{minHeight: 70, backgroundColor: 'white'}}/>
                <Routes>
                    <Route path={'/'} exact element={<Home/>}/>
                    <Route path={'/JiraCloudPlugins'} exact element={<JiraCloudPlugins/>}/>
                    <Route path={'/JiraPluginPage/:id'} exact element={<JiraPluginPage/>}/>
                    <Route path={'/UpdateJiraPlugin/:id'} exact element={<UpdateJiraPlugin/>}/>
                    <Route path={'/NewJiraCloudPlugin'} exact element={<NewJiraCloudPlugin/>}/>
                    <Route path={'/Login'} exact element={<SignIn/>}/>
                    <Route path={'/SignUp'} exact element={<SignUp/>}/>
                </Routes>
                {window.location.origin.includes("localhost") ?
                    <ReactQueryDevtools initialIsOpen={false}/> : null}
            </QueryClientProvider>
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
