import React from 'react';
import {Grid, Stack, Typography, ImageList, ImageListItem} from "@mui/material";
import bg from "../images/background.jpg";
//import {styled} from '@mui/system';


/*const StyledImage = styled('img')({
    width: '350',
    height: {xs:'180px', sm:'280px'},
    /!*borderColor: "white",*!/
});*/
function Home() {


    return (
        <Grid sx={{backgroundImage:`url(${bg})`,backgroundRepeat: 'no-repeat',backgroundPosition: 'center',
            backgroundSize:'100% 100%', minHeight: {xs:400, sm:330, md: 450,lg: 500}}} >
            <Grid container direction={'row'} spacing={1}  justifyContent={"center"} alignItems={"center"} >
                <Stack  justifyContent={"flex-end"}>
                    <Stack sx={{width:200}}>
                        <Stack sx={{minHeight: {xs:200,md:60, lg:150}}}/>
                        <ImageList sx={{width: {xs:150,sm:280}, height: {xs:150,sm:280}}} >
                            <ImageListItem>
                                <img src={require('../images/gear.png')}  alt="gear"/>
                            </ImageListItem>
                        </ImageList>
                    </Stack>
                </Stack>

                <Stack direction={{xs:'column', sm:'row'}} alignItems={"center"} justifyContent={"flex-end"}>
                    <Stack sx={{minWidth: 180}} />
                    <Stack/>
                    <Stack justifyContent={"flex-end"} mt={10}>
                        <Typography color={"white"} sx={{textAlign:'right', fontSize:{xs:20,sm:35}}}>
                            Empowering Jira's {<br />}Potential{<br />}With{<br />}PluginCraft
                        </Typography>
                    </Stack>
                </Stack>

            </Grid>

        </Grid>
    );
}

export default Home;