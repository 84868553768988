import React, {useEffect, useState} from 'react';
import {
    Box,
    Breadcrumbs,
    Button,
    ButtonGroup, Card, CardContent, CardMedia,
    Container, Divider,
    Grid, IconButton,
    Link,
    Stack, Typography,
} from "@mui/material";
import {getForJCP, deleteMessage} from "../query/Query";
import {useQuery, useQueryClient} from "react-query";
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {getStorage, ref, getDownloadURL, connectStorageEmulator} from "firebase/storage";
import {auth, firebaseStorage} from "../Firebase";
import {onAuthStateChanged} from "firebase/auth";

export const getImgUrl = async (keyImg) => {
    const storage = getStorage();
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
        connectStorageEmulator(firebaseStorage, "localhost", 9199);
    }
    const starsRef = ref(storage, keyImg);
    try {
        //const url = await getDownloadURL(starsRef);
        return await getDownloadURL(starsRef);
    } catch (err) {
        console.log("err is ", err);
        return null;
    }
};

//todo -- About this: this is the page to show the overview of all jira cloud plugins
function JiraCloudPlugins() {
    const queryClient = useQueryClient()
    const [filtered, setFiltered] = useState('all')
    const [imageUrls, setImageUrls] = useState({})
    const [authUser, setAuthUser] = useState(null);

    //to get user's auth to identify its role: admin/user, by this way, we can hide/show buttons of CRUD
    //todo:add role for profile when create profile page.
    const adminUid = [
        'cvwgGHngp9TZzOUFawudbNkevqo2'
    ]
    useEffect(() => {
        const listen = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
            } else {
                setAuthUser(null);
            }
        });
        return () => {
            listen();
        }
    }, []);

    // 3 functions to set filter with 'all', 'free'  or 'pro'
    const handleAllFilter = () => {
        setFiltered('all');
    }
    const handleFreeFilter = () => {
        setFiltered('free');
    }
    const handleProFilter = () => {
        setFiltered('pro');
    }

    //fetch data with filters
    const {isLoading: isLoadingMessagesData, data: messagesData} =
        useQuery(['messages', filtered], () => getForJCP(filtered).then(res => res))
    console.log("messagesData is ", messagesData);

    //to store image url from fetching
    useEffect(() => {
        if (!isLoadingMessagesData && messagesData && Array.isArray(messagesData)) {
            const updatedImageUrls = {};
            Promise.all(
                messagesData.map(async (result) => {
                    if (result.formData.keyImg) {
                        try {
                            updatedImageUrls[result.id] = await getImgUrl(result.formData.keyImg); // Add the new URL to the copy
                        } catch (error) {
                            console.error("Error fetching image URL:", error);
                            updatedImageUrls[result.id] = null; // Handle the error case
                        }
                    }
                })
            ).then(() => {
                setImageUrls(updatedImageUrls); // Update the state after all promises are resolved
                console.log("imageUrls is ", updatedImageUrls);
            });
        }
    }, [messagesData, isLoadingMessagesData]);

    //function od delete button
    const deletePlugin = (pluginId) => {
        try {
            deleteMessage(pluginId).then(() => {
                queryClient.invalidateQueries(['messages', filtered]);
            });
        } catch (error) {
            console.error('Error deleting plugin:', error);
        }
    };

//todo:try to use carousel to show images after modifying type of imgList in "newJiraCloudPlugin" to array instead of string.

    return (
        <Container sx={{minHeight: {sm: 300, md: 450}, paddingTop: 2}}>
            <Box direction={"row"} sx={{paddingX: 5, alignItems: "flex-start"}}>
                <Stack direction={"row"} role="presentation">
                    <Breadcrumbs aria-label="breadcrumb" sx={{flexGrow: 1, fontSize: 13}} color={'#9e9e9e'}>
                        <Link underline="hover" color="inherit" href="/">
                            All Plugins
                        </Link>
                        <Link underline="hover" color="inherit" href={"/JiraCloudPlugins"}>
                            Jira Cloud Plugins
                        </Link>
                    </Breadcrumbs>
                    <ButtonGroup>
                        <Button variant="text" sx={{marginX: 1, fontSize: 10}} onClick={handleAllFilter}>All Cloud
                            Plugins</Button>
                        <Button variant="text" sx={{marginX: 1, fontSize: 10}} onClick={handleFreeFilter}>For
                            Free</Button>
                        <Button variant="text" sx={{marginX: 1, fontSize: 10}} onClick={handleProFilter}>For
                            Pro</Button>
                        {authUser && adminUid.includes(authUser.uid) ?
                            <Button variant="filledTonal" sx={{marginX: 1, fontSize: 10}} href={'./NewJiraCloudPlugin'}>New
                                Plugin</Button> : null}
                    </ButtonGroup>
                </Stack>
            </Box>
            <Divider/>
            <Grid container justifyContent={'center'} sx={{paddingX: 15, paddingY: 6}}>

                {Array.isArray(messagesData) ? (
                        messagesData.map((result) => {
                            //product list: by using map to show every product's photos, name, description,price and a button of "see more"
                            return (
                                <Card key={result.id} sx={{display: 'flex', minWidth: {xs: 400, sm: 900}, marginY: 1}}>
                                    <CardMedia
                                        component="img"
                                        sx={{width: {xs: 150, sm: 400}, margin: 1, height: {xs: 100, sm: 'auto'}}}
                                        image={imageUrls[result.id]}
                                        alt={result.formData.pluginName}
                                    />
                                    <Divider orientation="vertical" flexItem sx={{my: 4, borderColor: '#e0e0e0'}}/>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        minWidth: {xs: 100, sm: 450},
                                        marginX: 1
                                    }}>
                                        <Grid sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}}}>
                                            <Stack direction={'row'} sx={{flexGrow: 1}} alignItems={'end'}>
                                                <CardMedia
                                                    component={'img'}
                                                    sx={{width: 80}}
                                                    image={result.formData.pluginName === "Issue Links Preview" ? require('../images/issue-logo.jpg') : require('../images/pro.png')}
                                                    alt="logo_with_scale"
                                                />
                                                <CardContent sx={{flexGrow: 1}}>
                                                    <Typography component="div" variant="h5">
                                                        {result.formData.pluginName} <Typography
                                                        style={{fontSize: 12}}>{result.formData.versionList}</Typography>
                                                    </Typography>
                                                </CardContent>
                                            </Stack>
                                            <Stack justifyContent={'center'}>
                                                {result.formData.scale === 'free'
                                                    ? <Stack direction={"row"} spacing={1} alignItems={'end'}>
                                                        <img src={require('../images/logo-free-gray.jpg')}
                                                             className={'App-logo'}
                                                             alt="free"/>
                                                    </Stack>
                                                    :
                                                    <Stack direction={"row"} spacing={1} alignItems={'end'}>
                                                        <img src={require('../images/logo-pro.jpg')}
                                                             className={'App-logo'}
                                                             alt="free"/>
                                                        <Typography variant="subtitle2" color="text.secondary"
                                                                    component="div"
                                                                    sx={{fontSize: 10}}>{/* when textDecoration:'line-through' needed*/}
                                                            $ {result.formData.price} /month
                                                        </Typography>
                                                    </Stack>
                                                }
                                            </Stack>
                                        </Grid>

                                        <CardContent sx={{
                                            flex: '1 0 auto',
                                            paddingLeft: 6,
                                            height: 80,
                                            width: 400,
                                            overflowY: 'scroll',
                                            overflow: 'hidden'
                                        }}>
                                            <Typography fontSize={13} variant="subtitle3" color="text.secondary"
                                                        component="div">
                                                {result.formData.description}
                                            </Typography>
                                        </CardContent>
                                        <Stack sx={{paddingLeft: {xs: 2, sm: 11}}} direction={{xs: 'column', sm: 'row'}}>
                                            <Stack direction={'row'}>
                                                <IconButton
                                                    disabled> {/* todo: enable it when we create relative links*/}
                                                    <ShareIcon sx={{color: '#01579b'}}/>
                                                </IconButton>
                                                <IconButton disabled>
                                                    <FavoriteIcon sx={{color: '#01579b'}}/>
                                                </IconButton>
                                            </Stack>
                                            <Stack direction={'row'} sx={{marginX: 3}} spacing={2}>
                                                <Button sx={{fontSize: 10}} size="small"
                                                        href={"/JiraPluginPage/:" + result.id}>Learn More</Button>
                                                {authUser && adminUid.includes(authUser.uid) ?
                                                    <Stack direction={'row'}>
                                                        <Button sx={{fontSize: 10}} size="small" variant="filledTonal"
                                                                href={"/UpdateJiraPlugin/:" + result.id}>Update</Button>
                                                        {/*todo: create a new page for update plugin*/}
                                                        <Button sx={{fontSize: 10}}
                                                                variant="filledTonal"
                                                                size="small"
                                                                onClick={() => deletePlugin(result.id)}>delete
                                                        </Button>
                                                    </Stack>
                                                    : null}
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </Card>
                            );
                        }))
                    : <div>Loading...</div>}
            </Grid>
        </Container>
    );
}

export default JiraCloudPlugins;