import React, {useState} from "react";
import {Button, Typography, FormControl, Box, TextField, Stack, IconButton, Divider, Grid, Link} from "@mui/material";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BrushIcon from '@mui/icons-material/Brush';
import PersonIcon from "@mui/icons-material/Person";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import {createUserWithEmailAndPassword} from "firebase/auth";
import {auth} from "../../Firebase";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";


const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/JiraCloudPlugins";

    const signUpSubmit = (e) => {
        e.preventDefault();
        navigate(from, {replace: true});
        if (password !== confirmPassword) {
            console.log('Passwords do not match');
            setErrors("Passwords do not match")
            return;
        }
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                console.log(userCredential)
            }).catch((error) => {
            setErrors("Error signing up: " + error.message);
            console.log('error in sign up:', error)
        })
    }


    return (
        <Box
            height="75vh" // Set the height of the container to 100% of the viewport height
            display="flex" // Use flexbox display
            justifyContent="center" // Horizontally center the content
            alignItems="end" // Vertically center the content
        >
            <Grid>
                <Stack direction={'row'}>
                    <IconButton >
                        <AssignmentIndIcon fontSize={'large'} edge={'start'} color={'secondary'}
                                           aria-label={'client-avatar'}/>
                        <BrushIcon fontSize={'small'} edge={'start'} color={'secondary'} aria-label={'client-key'}/>
                    </IconButton>
                    <Grid margin={2} width={{xs:150,sm:380}} >
                        <Typography>Create A New Account</Typography>
                        <Stack component={'form'} onSubmit={signUpSubmit} spacing={2} margin={3}>
                            {errors && (
                                <Typography color="error" fontSize={10}>
                                    {errors}
                                </Typography>)
                            }
                            <FormControl>
                                <TextField
                                    label={'Enter your email'}
                                    name={'email'}
                                    variant="standard"
                                    type={'email'}
                                    value={email}
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <TextField
                                    label={'Enter your password'}
                                    name={'password'}
                                    type={'password'}
                                    variant={'standard'}
                                    value={password}
                                    required
                                    onChange={(e) => setPassword(e.target.value)}/>
                                <TextField
                                    label={'Confirm your password'}
                                    name={'confirmPassword'}
                                    type={'password'}
                                    variant={'standard'}
                                    value={confirmPassword}
                                    required
                                    onChange={(e) => setConfirmPassword(e.target.value)}/>
                            </FormControl>

                            <Button type={'submit'} variant="contained" color={'secondary'}>Sign Up</Button>
                        </Stack>
                    </Grid>
                </Stack>

                <Divider >OR</Divider>

                <Stack direction={'row'} >
                    <IconButton href={'/Login'}>
                        <PersonIcon fontSize={'large'} edge={'start'} color={'primary'}
                                    aria-label={'client-avatar'}/>
                        <VpnKeyIcon fontSize={'small'} edge={'start'} color={'primary'} aria-label={'client-key'}/>
                    </IconButton>
                    <Grid marginX={3} marginY={5} width={{xs:150,sm:330}} >
                        <Typography fontSize={12} >Already have an account?
                            <Link underline={'always'} href={'/Login'}>
                                click here to login!
                            </Link>

                        </Typography>
                    </Grid>
                </Stack>
            </Grid>

        </Box>
    )
}
export default SignUp