import React, {useEffect,useState} from 'react';
import {
    AppBar,
    Button,
    IconButton,
    Toolbar,
    Link,
    Stack,
    styled,
    InputBase,
    alpha,
    Menu,
    MenuItem,
    Divider, Typography
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import header from '../images/header.png';
import {onAuthStateChanged, signOut} from "firebase/auth";
import {auth} from "../Firebase";
import {Avatar} from "antd";

function Header() {
    const [authUser,setAuthUser] = useState(null);

    useEffect(() => {
        const listen = onAuthStateChanged(auth,(user) => {
            if(user) {
                setAuthUser(user);
            }else {
                setAuthUser(null);
            }
        });
        return ()=> {
            listen();
        }
    },[]);

    const userSignOut = () => {
        signOut(auth).then(() => {
            console.log('sign out successful')
        }).catch(error=> console.log(error))
    }

    /*for the css of the search part*/
    const Search = styled('div')(({theme}) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    }));
    const SearchIconWrapper = styled('div')(({theme}) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));
    const StyledInputBase = styled(InputBase)(({theme}) => ({
        color: 'inherit',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '12ch',
                '&:focus': {
                    width: '20ch',
                },
            },
        },
    }));
//for menu list
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElS, setAnchorElS] = useState(null);
    const [anchorElA, setAnchorElA] = useState(null);
    const openP = Boolean(anchorEl);
    const openS = Boolean(anchorElS);
    const openA = Boolean(anchorElA);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClickS = (event) => {
        setAnchorElS(event.currentTarget);
    };
    const handleClickA = (event) => {
        setAnchorElA(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCloseS = () => {
        setAnchorElS(null);
    };
    const handleCloseA = () => {
        setAnchorElA(null);
    };

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';
        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }
    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    return (
        <React.Fragment>
            <AppBar position="fixed" sx={{backgroundColor: 'white', border: 'solid', borderWidth: 1}}>
                <Toolbar sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    backgroundImage: `url(${header})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 100%'
                }}>

                    <Button href={'/'}>
                        <img src={require('../images/logo-black.png')} className="App-logo" alt="logo"/>
                    </Button>
                    <Link href={"/"} color={'inherit'} underline={'none'} sx={{flexGrow: 1}}>PluginCraft</Link>
                    {/*sx={{flexGrow:1}} allows us to set all content at right except typography */}
                    <Stack direction={'row'} spacing={4} sx={{ml: 3}}>
                        <div>
                            <Button
                                id="plugins-button"
                                aria-controls={openP ? "plugins-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={openP ? "true" : undefined}
                                onClick={handleClick}
                                color={'inherit'}
                                sx={{fontSize: 13}}
                            >
                                Plugins
                            </Button>
                            <Menu
                                id="plugins-menu"
                                aria-labelledby="plugins-button"
                                anchorEl={anchorEl}
                                open={openP}
                                onClose={handleClose}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left"
                                }}
                            >
                                <MenuItem component="a" href={"/JiraCloudPlugins"} onClick={handleClose}>Jira Cloud
                                    Plugins</MenuItem>
                                <MenuItem disabled component="a" href={"/"} onClick={handleClose}>Jira Software
                                    Plugins</MenuItem>
                                <Divider/>
                                <MenuItem disabled component="a" href={"/"} onClick={handleClose}>View All</MenuItem>
                            </Menu>

                            <Button
                                id="docs-button"
                                aria-controls={openS ? "docs-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={openS ? "true" : undefined}
                                onClick={handleClickS}
                                color={'inherit'}
                                sx={{fontSize: 13}}
                            >
                                Services
                            </Button>
                            <Menu
                                id="docs-menu"
                                aria-labelledby="docs-button"
                                anchorEl={anchorElS}
                                open={openS}
                                onClose={handleCloseS}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left"
                                }}
                            >
                                <MenuItem disabled onClick={handleCloseS}>Docs</MenuItem>
                                <MenuItem component="a"
                                          href={"https://plugincraft.atlassian.net/servicedesk/customer/portal/2"}
                                          onClick={handleCloseS}>Support</MenuItem>
                                <MenuItem component="a" href={"https://marketplace.atlassian.com/"}
                                          onClick={handleCloseS}>Atlassian Marketplace</MenuItem>
                                <Divider/>
                                <MenuItem disabled onClick={handleCloseS}>Contact Us</MenuItem>
                            </Menu>
                        </div>
                    </Stack>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon/>
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{'aria-label': 'search'}}
                        />
                    </Search>

                    <div>
                        <IconButton
                            id="account-button"
                            aria-controls={openS ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openS ? "true" : undefined}
                            onClick={handleClickA}
                            color={'inherit'}
                            sx={{fontSize: 13}}
                        >
                            <PersonIcon/>
                        </IconButton>
                        <Menu
                            id="account-menu"
                            aria-labelledby="account-button"
                            anchorEl={anchorElA}
                            open={openA}
                            onClose={handleCloseA}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left"
                            }}
                        >
                            {authUser?
                                <Stack>
                                    <Typography paddingLeft={1} fontSize={13}>Account</Typography>

                                    <Stack direction={'row'} margin={1} spacing={1}>
                                        <Avatar {...stringAvatar('Michel Wang')} />
                                        <Stack>
                                            <Typography fontSize={12}>{authUser.uid === 'cvwgGHngp9TZzOUFawudbNkevqo2'? 'Admin' : 'User'}</Typography>
                                            <Typography>{authUser.email}</Typography>
                                        </Stack>

                                    </Stack>
                                    <MenuItem component="a" href={'/'} disabled>Manage account</MenuItem>
                                    {/*todo: add user profile in next phase*/}
                                    <Divider/>
                                    <MenuItem onClick={userSignOut}>Log out</MenuItem>
                                </Stack>
                            :
                                <Stack>
                                    <MenuItem component="a" href={'/Login'}>Login to account</MenuItem>
                                    <MenuItem component="a" href={'/SignUp'}>Create a new account</MenuItem>
                                </Stack>

                            }
                        </Menu>
                    </div>

                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}


export default Header;